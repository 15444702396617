/********************************************************
*
*
*     Cookie compliance stylesheet
*
*
*********************************************************/

// Variables
$background-colour: $brandDkBlue;
$site-font: 'FS Elliot', sans-serif;
$btn-background:  $brandLtBlue;
$btn-background-sec:  $brandGreen;

div {

  &#sliding-popup {
    background: $background-colour;

    button {

      &.eu-cookie-withdraw-tab {
        position: absolute;
        top: 0;
        transform: translate(0, -100%);
        left: 0;
        border-radius: 0;
        border: none;
        background-color: $background-colour;
        color: $white;
      }
    }

    h1,
    h2,
    h3,
    p {
      font-family: $site-font;
      color: $white;
    }

    h2 {
      @include font-size(22);
    }

    .popup-content {
      padding:15px 0;

      .eu-cookie-compliance-buttons {
        padding-top: 30px;

        button {
          display: block;
          margin-bottom: 20px;
          background-color: $btn-background;;
          border:1px solid $btn-background;;
          color: $white;
          transition: all 0.5s ease-in-out;

          &:hover {
            background-color: $btn-background-sec;
            border-color: $btn-background-sec;
            color: $background-colour;
          }
        }
      }
    }
  }
}


