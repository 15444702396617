/****************************************
*
* Navigation stylesheet
*
* Styles for Bootstrap 4 Navigation
*
* Property declaration order
*
* position
* display
* box model
* color
* text
* other
*
*****************************************/
