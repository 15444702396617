.video-wrapper {
  
  @media only screen and (min-width: 768px) {
    padding-bottom: 48.25%;
  }

  padding-bottom: 50.25%;
  padding-top: 25px;
  height: 0;
  > * {
  	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}