/****************************************
*
*  override styles for flexslider
*
*****************************************/

.flexslider {
  margin: 0 0 40px;
  -moz-box-shadow: none;
  -o-box-shadow: none;
   box-shadow: none;

  .flex-control-nav {
    display: none;
  }

  .flex-direction-nav {

    a {
      display: block;
      width: 42px;
      height: 42px;
    }

    .flex-prev {
      background-image: url("#{$pathToImages}/white-arrow-left.png");
      background-size: 42px 42px;
      text-indent: 10000px;
      opacity: 1;
    }

    .flex-next {
      background-image: url("#{$pathToImages}/white-arrow-right.png");
      background-size: 42px 42px;
      text-indent: 10000px;
      opacity: 1;
      text-align: right;
    }

    .flex-direction-nav a.flex-prev:before  {
      content: none;

    }

    .flex-direction-nav a.flex-next:before  {
      content: none;
    }
  }
}

.case-studies {

  .flexslider {

    .flex-direction-nav {
      a {
        display: block;
        width: 30px;
        height: 30px;
      }

      .flex-prev {
        left: -50px;
      }

      .flex-next {
        right: -50px;
      }

      &:hover {
        .flex-prev {
          left: -50px;
        }

        .flex-next {
          right: -50px;
        }
      }
    }

    .flex-prev {
      background-image: url("#{$pathToImages}/process-arrow.png");
      background-size: 30px 30px;
      text-indent: 10000px;
      opacity: 1;
      transform: rotate(180deg);
    }

    .flex-next {
      background-image: url("#{$pathToImages}/process-arrow.png");
      background-size: 30px 30px;
      text-indent: 10000px;
      opacity: 1;
      text-align: right;
    }
  }
}