/****************************************
*
* Typographic styles
*
*****************************************/

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

// TODO: following font files to be removed when licence obtained to use Adobe fonts CDN

@font-face {
  font-family: 'FS Elliot';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Regular'), local('Myriad-Pro-Regular'), url('#{$pathToFonts}/fs-elliot-regular.woff2') format('woff2');
}

@font-face {
  font-family: 'FS Elliot';
  font-style: normal;
  font-weight: 700;
  src: local('Myriad Pro Bold'), local('Myriad-Pro-Bold'), url('#{$pathToFonts}/fs-elliot-bold.woff2') format('woff2');
}




$FS-Elliot-font: 'FS Elliot', sans-serif;
.fs-elliot-pro-font {font-family: $FS-Elliot-font};



$sourceSansPro: 'sans-serif';

//font weights
$regular:   400;
$bold:      700;


.regular          {font-weight: $regular;}
.bold             {font-weight: $bold;}

.centre {text-align: center}

h1{
  font-family: $FS-Elliot-font;
  font-weight: $regular;
  @include font-size(65);
}

h2 {
  font-family: $FS-Elliot-font;
  font-weight: $regular;
  @include font-size(45);

  &.panel-brand-heading {
    @include font-size(30);
    padding: 118px 0 40px;
    font-weight: $bold;
    letter-spacing: 5.5px;
    text-transform: uppercase;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 66px;
      height: 188px;
      top:0;
      left:50%;
      transform: translateX(-50%);
      background: transparent url("#{$pathToImages}/brand-heading-bg-.png") 0 0 no-repeat;
      background-size: 66px 188px;
    }
  }

  &.short-underline {
    position: relative;
    @include font-size(26);
    font-weight: $bold;
    letter-spacing: 4.5px;
    padding-bottom: 15px;
    margin-bottom: 30px;

    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 120px;
      height: 3px;
      bottom:0;
      left:50%;
      transform: translateX(-50%);
      background-color: $brandLtBlue;
    }
  }
}

