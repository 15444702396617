/****************************************
*
* Global stylesheet
*
*****************************************/
body {

  /*&:before {
    content: '1280 and above';
    position: fixed;
    top:0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1000;
  }*/

  .fixed-width-content {
    max-width: 1280px;
    margin: 0 auto;
  }

  .page-wrapper {
    position: relative;

    header {
      position: inherit;
      height: 160px;

      .logo {
        position: absolute;
        display: block;
        top:42px;
        left: 50px;
        width:243px;
        height: 77px;
        background: transparent url("#{$pathToImages}/the-hub-logo.png") 0 0 no-repeat;
        background-size: 243px 77px;

        h1,
        p {
          text-indent: -100000px;
          display: inline-block;
        }


      }

      #search-trigger {
        display: none;

        button {
          border: none;
          color: $white;
          background: transparent url("#{$pathToImages}/magnifing-glass.png") 5px 5px no-repeat;
          background-size: 25px 25px;
          display: block;
          width: 30px;
          height:30px;
          text-indent: -10000px;
          cursor: pointer;
        }
      }

      #search-wrapper {

        &.on {
          display: inline-block;
          position: absolute;
          right:50px;
          top: 75px;
        }
      }
      .page-search-wrapper {
        display: inline-block;
        position: absolute;
        right:50px;
        top: 30px;
        height: 50px;

        .page-search {
          margin:44px 0 0;
          margin-left: auto;
          display: inline-block;

          .page-search-form {
            @include flex-container(row, flex-start);
            @include border-radius(6px);
            border: 2px solid $brandSecondary;

            .form-item {

              label {
                display: none;
              }

              input {
                display: inline-block;
                border:none;
                background: transparent;
                padding:0 10px;
                color: $brandPrimary;
                height: 42px;
                min-width: 180px;
                @include font-size(18);
                font-weight: $regular;

                &::placeholder {
                  color: $brandSecondary;
                  text-align: center;
                }
              }
            }

            .form-actions {

              input[type=submit] {
                border: none;
                color: $brandSecondary;
                background: transparent url("#{$pathToImages}/magnifing-glass.png") 50% 50% no-repeat;
                background-size: 20px 20px;
                display: block;
                width: 65px;
                height:42px;
                text-indent: -10000px;
                cursor: pointer;

              }
            }
          }
        }
      }

      .hamburger {
        position: absolute;
        top: 40px;
        right: 36px;
        display: none;
        width:32px;
        height: 22px;
        cursor: pointer;

        span {
          display: block;
          width: 100%;
          height: 4px;
          background-color: $brandLtBlue;
          @include centerV;

          &:before {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            position: absolute;
            bottom:-7px;
            background-color: $brandLtBlue;
            transition: all, 0.3s ease-in-out;

          }

          &:after {
            content: '';
            display: block;
            width: 100%;
            height: 4px;
            position: absolute;
            top:-7px;
            background-color: $brandLtBlue;
            transition: all, 0.3s ease-in-out;
          }
        }

        &:hover {

          span {

            &:before {
              bottom:-10px;
            }

            &:after {
              top:-10px;
            }
          }
        }
      }

      nav {

        &.user-nav {

          ul {
            position: absolute;
            top: 10px;
            right: 50px;
            height: 30px;
            @include flex-container(row, flex-start);

            li {

              a {
                color: $brandLtBlue;
                @include font-size(18);
                font-weight: $regular;
                text-decoration: none;
                padding-left: 12px;
              }

              &:not(:last-child) {

                a {
                  padding-right: 12px;
                  border-right: 1px solid $brandLtBlue;
                }
              }
            }
          }
        }
      }
    }

    .main-navigation {
      height: 40px;
      position: relative;
      background-color: $brandSecondary;

      nav {

        &.navbar {
          margin: 0 auto;
          border-top: 1px solid $brandSecondary;

          ul {
            list-style: none;
            text-align: center;

            li {
              display: inline-block;

              a {
                display: inline-block;
                height:40px;
                padding:0 36px;
                @include font-size(18);
                font-weight: $regular;
                line-height: 40px;
                text-decoration: none;
                color: $white;

                &:hover {
                  color: $brandPrimary;
                  background-color: $white;
                }
              }
            }

            li {

              &.dropdown {

                &.open {

                  div {

                    &.dropdown-menu {
                      display: block;
                      position: absolute;
                      overflow: hidden;
                      z-index: 100;
                      top: 40px;
                      left: 0;
                      width: 100%;
                      min-height: 100px;
                      padding: 27px 36px 36px 0;
                      background-color: $white;
                      -moz-box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.5);
                      box-shadow: 0px 5px 5px 0px rgba(153,153,153,0.5);

                      ul {
                        text-align: left;
                      }

                      & > ul {
                        @include flex-container(row,flex-start);

                        li {
                          width: 17%;

                          a {
                            color: $brandPrimary;
                            line-height: 22px;
                            font-weight: bold;
                            display: inline-block;
                            height: 70px;


                            &:hover {
                              color: $brandSecondary;
                            }
                          }

                          & > ul {
                            width: 100%;
                            @include flex-container(column,flex-start);
                            margin-top: 20px;

                            li {
                              width: inherit;
                              margin-bottom: 10px;

                              a  {
                                color: $brandPrimary;
                                font-weight: $regular;
                                display: inline-block;
                                height: auto;

                                &:hover {
                                  color: $brandSecondary;
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }

                  a {
                    color: $brandPrimary;
                    background-color: $white;
                  }
                }

                div {

                  &.dropdown-menu {
                    display: none;
                  }
                }

              }
            }
          }
        }
      }
    }

    .content-wrapper {
      min-height: 275px;
      overflow: visible;
      height: auto;

      .blog-content {
        @include flex-container(row, space-between);
        margin-top: 85px;

        & > article {
          width: 65.625vw;
          margin-top: -60px;

          .page-title {

            padding-left: 36px;

            h1 {
              text-align: left;
              @include font-size(36);

            }

            .post-date {
              position: relative;
              top: -5px;
              @include font-size(22);
              font-weight: $regular;
              color: $brandLtBlue;
            }
          }

          .content {

            .wysiwyg {

              ol,
              ul {

                &:not(:last-child) {
                  margin-bottom: 30px;
                }

                li {
                  margin: 0;
                  line-height: 28px;
                }
              }
            }

            .special-image-block {
              padding: 0 36px;
              width: 100%;

              img {
                width: 100%;
                margin:0;
              }
            }

            .video-container {
              padding: 0 36px;
              position: relative;
              padding-bottom: 56.25%;
              padding-top: 30px;
              height: 0;
              overflow: hidden;
              margin: 0 36px 0 36px;
            }

            .video-container iframe,
            .video-container object,
            .video-container embed {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          }
        }

        & > aside {
          width: 34.375vw;
          @include flex-container(column, flex-start);

          & > section {
            overflow: hidden;

            .section-content {
              min-height: 400px;
              padding: 36px;
              overflow: hidden;

              &.margin-bottom {
                margin-bottom: 15px;
              }

              h2 {

                &.section-heading {
                  @include font-size(28);
                  color: $white;
                  font-weight: $regular;
                  margin-bottom: 30px;
                }
              }

              .latest-news-wrapper {

                .latest-news-item {
                  display: block;
                  position: relative;
                  margin-bottom: 20px;

                  a {

                    h3 {
                      @include font-size(18);
                      color: $white;
                      font-weight: $regular;
                      margin: 0;
                      line-height: 27px;
                    }

                    p {
                      @include font-size(18);
                      color: $brandDkBlue;
                      font-weight: $bold;
                      margin: 0;
                      line-height: 27px;
                    }

                    &:hover {

                      h3,
                      p {
                        text-decoration: underline;
                      }
                    }
                  }
                }
              }

              .tags-wrapper {

                .tag {
                  display: inline-block;
                  border: 2px solid $white;
                  @include border-radius(6px);
                  padding: 10px 23px;
                  @include font-size(18);
                  line-height: 1;
                  font-weight: $regular;
                  margin: 0 6px 6px 0;
                  color: $white;
                  text-decoration: none;
                  transition: all .5s ease-in-out;

                  &:hover {
                    color: $brandNeonPink;
                    background-color: $white;
                  }
                }
              }
            }
          }
        }
      }

      .dynamic-row {
        @include flex-container(row, flex-start);
        flex-wrap: wrap;

        .block {

          a {
            transition: all, 0.5s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }

            &.main-block {

              &:hover {

                transform: scale(1.05);
              }
            }
          }
        }

        .dynamic-row {

          & > div {

            &.size1of2 {

              a {

                .block-inner-content {
                  padding: 22px 30px;

                  h2 {
                    @include font-size(22);
                  }
                }

                &:hover {

                }
              }
            }
          }
        }

        & > div {

          &.size1of2 {

            a {

              .block-inner-content {
                padding: 22px 30px;

                h2 {
                  @include font-size(30);
                }
              }
            }
          }
        }

        .block {

          a {

            .block-inner-content {
              padding: 13px 0 13px 30px;

              h2 {
                width:100%;
                @include font-size(22);
                line-height: 27px;
              }
            }
          }
        }
      }

      .block {
        height:auto;
        overflow: hidden;
        position: relative;

        a {
          color: $white;text-decoration: none;

          .block-inner-content {
            position: absolute;
            bottom:0;
            left: 0;
            width: 100%;
            padding:0 0 30px 30px;

            h2 {
              @include font-size(24);
              font-weight: $bold;
              width:70%;
            }
          }
        }

        .image-link-block {
          display: block;
          width: 100%;
          height: 100%;

          img {
            display: block;
            width: 100%;
            object-fit: cover;
            transition: all, 0.5s ease-in-out;
          }
        }

        .link-block {
          display: block;
          width: 100%;
          height: 100%;
        }

        .aspect-box {
          padding-top: 100%;
        }
      }

      .page-title {

        h1 {
          @include font-size(42);
          font-weight: $bold;
          color: $brandDkBlue;
          text-align: center;
          margin: 40px 0 0;

          &.step-heading {
            max-width: 1280px;
            margin: 40px auto 0;
            padding: 0 36px;
            text-align: left;
          }
        }
      }

      .content {

        &.underlined {

          .fixed-width-content {
            position: relative;
            //border-bottom: 1px solid $brandDkBlue;

            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              display: block;
              width: 100%;
              margin: 0 36px;
              border-bottom: 1px solid $brandDkBlue;
            }
          }
        }

        & + .content {
          margin-top: 0;
          margin-bottom: 0;

          &.underlined {
            margin-top: 30px;
          }

          .wysiwyg {
            padding: 0 36px 30px;
          }
        }

        & + .twin-image {
          margin-top: 0;
        }

        &.intro-section {

          .wysiwyg {
            padding: 0 36px;

            p {
              @include font-size(22);
              font-weight: $regular;
              text-align: center;
              padding:0 135px 36px;
            }
          }

          &.step-content {

            .wysiwyg {
              padding: 0;

              p{
                text-align: left;
                padding:0 36px 36px;
              }
            }
          }
        }

        .wysiwyg {
          padding:30px 36px;

          h2 {
            @include font-size(28);
            font-weight: $bold;
            color: $brandDkBlue;
          }

          p {
            @include font-size(20);
            font-weight: $regular;
            text-align: left;
            color: $txtColour;
          }
        }
      }

      .table-section {
        margin-bottom: 30px;

        .table-wrapper {
          padding: 0 36px;
          overflow: hidden;

          .flex-row {
            @include flex-container(row, space-between);
            flex-wrap: wrap;

            &.double-row {
              .block {
                width:calc((50% - 15px));
              }
            }

            &.treble-row {
              .block {
                width:calc((33.33% - 20px));
              }
            }

            &:not(:last-child) {
              margin-bottom: 30px;
            }

            .block {

              &:not(:nth-child(1)) {
                margin-left: 15px;
              }

              &:not(:last-child) {
                margin-right: 15px;
              }
            }
          }



          .flex-row {
            @include flex-container(row, space-between);

            .table-block {
              display: block;

              table {
                width: 100%;
              }
            }
          }
        }
      }

      .two-col-content {

        .content-row {
          @include flex-container(row, space-between);


          & > div {
            width:46.5%;

            &.table-block {
              width: 50%;
              margin-bottom: 30px;

              &:last-child {
                padding-right: 36px;

                table {

                }
              }

              &:nth-child(1) {
                padding-left: 36px;

                table {

                }
              }

            }
          }

          .wysiwyg {

            h2 {

              &:nth-child(1) {
                //margin-top: -42px;
              }
            }
          }

          .content-col {

            .inner {
              padding:0 36px;

              &.info-panel {
                margin-bottom: 60px;

                p {
                  margin-bottom: 30px;
                  @include font-size(20);
                  font-weight: $regular;
                }

                h4 {

                  &.sub-head {
                    @include font-size(20);
                    font-weight: $bold;
                    margin-bottom: 5px;
                  }
                }

                img {
                  width:100%;

                  &+ p {
                    margin-top: 30px;
                  }
                }
              }

              a {

                h3 {
                  margin:0;
                }
              }
            }

          }
        }
      }

      .vcard {
        margin-bottom: 30px;

        span {
          display: block;

          &.adr {
            margin-bottom: 30px;
          }

          &.region,
          &.postal-code {
            display: inline;
          }
        }
      }

      .contact-us-section {
        overflow: hidden;

        .contact-us-content {
          @include flex-container(row, space-between);
          width: 93.75vw;
          margin: 0 auto;

          & > div {
            width: 48.828vw;

            &:not(:last-child) {
              margin-right: 30px;
            }

            &:nth-child(2) {
              @include flex-container(column, space-between);
            }
          }

          .social-media-block {
            position: relative;
            height: 370px;
            overflow: hidden;

            img {
              position: absolute;
              z-index: -1;
              top: 0;
              left: 0;
              width: 100%;
              min-height: 370px;
              object-fit: cover;
            }

            .inner {
              margin:0;
              height: inherit;
            }

            .social-media {
              color: $white;
              text-align: center;
              padding-top:80px;
              height: 100%;

              a {
                color: $white;
                @include font-size(64);
                margin-right: 25px;

                &:hover {

                  i {
                    opacity: 0.5!important;
                  }
                }
              }

              h3 {
                @include font-size(40);
                font-weight: $regular;
                width:100%;
                margin-top: 0;
              }

              p {
                @include font-size(21);
                margin-top:96px;
              }
            }
          }

          .contact-us-image-block {
            height: 370px;
            width: 100%;
            overflow: hidden;

            .image-link-block {
              transition: all .5s ease-in-out;

              img {
                object-fit: cover;
                height: 100%;
              }

              &:hover {

                img {
                  transform: scale(1.1);
                }
              }
            }
          }


          form {
            display: block;
            width: 80%;
            margin: 0 auto;
          }

          .privacy-policy {
            margin-top: 30px;
            text-align: center;

            a {
              color: $white;
              @include font-size(20);
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .hi-lite-text {
        position: relative;
        color: $brandLtBlue;
        padding: 60px 100px;

        .wysiwyg {
          padding: 0;
        }

        p {
          @include font-size(33);
          font-weight: $regular;
          text-align: center;
          line-height: 43px;
        }

        &.brandDkBlueBg,
        &.brandLtBlueBg,
        &.brandGreenBg,
        &.brandPinkBg,
        &.brandMustardBg {
          color: $white;
          padding: 60px 100px;

          &.quotes {
            color: $white;

            &:before {
              background: transparent url("#{$pathToImages}/open-quote-marks-white.png") 0 0 no-repeat;
              background-size: 68px 58px;
            }

            &:after {
              background: transparent url("#{$pathToImages}/closed-quote-marks-white.png") 0 0 no-repeat;
              background-size: 68px 58px;
            }
          }

          & + .two-col-content {
            margin-top: 50px;

            .content-row {

              .wysiwyg {

                h2 {

                  &:nth-child(1) {
                    margin-top: 0;
                  }
                }
              }
            }
          }
        }

        &.quotes {
          padding: 4.687vw 13.281vw;

          &:before {
            content: '';
            display: block;
            width: 5.312vw;
            height:4.531vw;
            position: absolute;
            top: 40px;
            left: 7.812vw;
            background: transparent url("#{$pathToImages}/open-quote-marks.png") 0 0 no-repeat;
            background-size: 5.312vw 4.531vw;
          }

          &:after {
            content: '';
            display: block;
            width: 5.312vw;
            width: 5.312vw;
            height:4.531vw;
            position: absolute;
            bottom: 40px;
            right: 7.812vw;
            background: transparent url("#{$pathToImages}/closed-quote-marks.png") 0 0 no-repeat;
            background-size: 5.312vw 4.531vw;
          }
        }

        & + .two-col-content {

          .content-row {

            .wysiwyg {

              h2 {

                &:nth-child(1) {
                  margin-top: -42px;
                }
              }
            }
          }
        }
      }

      .downloads {
        padding:36px;

        header {
          color: $white;
          height: 90px;
          margin: 0;

          h2 {
            @include font-size(35);
          }
        }

        .downloads-body {
          @include flex-container(row, space-between);
          flex-wrap: wrap;

          .download-box {
            position: relative;
            margin-bottom:2.343vw;
            width: 31.62%;
            height: 256px;
            border:2px solid $white;
            @include border-radius(10px);
            background-color: transparent;

            .block-inner-content {
              padding: 30px 42px;
              height:100%;
              color: $white;

              h3 {
                @include font-size(24);
                font-weight: $bold;
              }

              p {
                @include font-size(20);
                font-weight: $regular;
                line-height: 24px;


              }

              a {
                position: static;
                display: inline;

                &.download {
                  padding:15px 35px 0 0;
                  margin-top: 8px;
                  display: inline-block;
                  background: transparent url("#{$pathToImages}/download-arrow.png")100% 0 no-repeat;
                  @include font-size(20);
                  font-weight: bold;
                  line-height: 24px;
                  background-size: 27px 39px;
                }

                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }

        footer {
          background-color: transparent;
          position: relative;
          height: auto;
          min-height:50px;

          .see-more-link {@include centerer;
            @include font-size(24);
            font-weight: $regular;
            text-align: center;
            color: $white;

          }


        }
      }

      .search-page-form {
        position: relative;
        height: 135px;

        .search-page-form-content {
          @include flex-container(column, center);
          min-height: 0;
          padding: 0 50px ;

          h2 {
            margin-top: 46px;
            @include font-size(36);
            text-align: center;
            //color: $white;

            span {

              &.search-term {
                font-style: italic;
                position: static!important;
                display: inline;

                &.quotes{

                  &:before {
                    content: open-quote;
                  }
                  &:after {
                    content: close-quote;
                  }
                }
              }
            }
          }

          .search-form {
            display: none;
            height: auto;

            form {

              &.search-form {

                .form-item {
                  @include flex-container(column, center);
                  text-align: center;
                  margin-bottom: 30px;

                  label {
                    //font-family: $Roboto-font;
                    @include font-size(24);
                    color: $white;
                  }

                  input {
                    //height: 53px;
                    width:50%;
                    padding:10px 25px;
                    margin:0 auto;
                    outline: none;
                    color: $brandDkBlue;
                    //font-family: $Roboto-font;
                    @include font-size(18);
                    font-weight: $regular;

                    &::placeholder {
                      //font-family: $Roboto-font;
                      @include font-size(18);
                      font-style: italic;
                      //color: $tblBgDkGrey;
                    }
                  }
                }

                input[type=submit] {
                  display: inline-block;
                  height: 53px;
                  width: 25%;
                  padding: 0 50px;
                  margin:0 auto;
                  border: 2px solid $white;
                  @include border-radius(6px);
                  background-color: transparent;
                  color: $white;
                  //font-family: $Roboto-font;
                  @include font-size(24);
                  line-height: 53px;
                  font-weight: $regular;
                  transition: all 0.5s ease-in-out;
                  outline: none;

                  &:hover {
                    background-color: $white;
                    color: $brandDkBlue;
                  }
                }
              }
            }
          }
        }
      }

      .search-page-form-content {
        padding: 0 50px;
      }

      .search-page-results {

        & + .actions {
          margin-top:-20px;
        }

        .search-page-results-content {
          padding:50px;

          h2 {
            text-align: center;
            color: $brandDkBlue;
            font-family: $FS-Elliot-font;
            margin-bottom: 40px;
          }

          ol {
            list-style: none;
            padding-left:60px;
            counter-reset: list-counter;

            li {
              position: relative;
              @include font-size(18);
              font-weight: $regular;
              margin-bottom: 10px;
              border-bottom: 1px solid $mid-grey;

              &:before {
                content: counter(list-counter);
                counter-increment: list-counter;
                position: absolute;
                top: 5px;
                left:-60px;
                display: inline-block;
                height: 36px;
                width: 36px;
                color: $white;
                background-color: $brandLtBlue;
                font-family: $FS-Elliot-font;
                @include font-size(24);
                font-weight: $regular;
                text-align: center;
                line-height: 36px;
              }

              h3 {
                text-align: left;
                font-family: $FS-Elliot-font;
                font-weight: $regular;
                margin-top: 40px;
                @include font-size(36);

                a {
                  color: $brandPrimary;

                  &:hover {
                    color: $brandLtBlue;
                    text-decoration: none;
                  }
                }
              }

              p {
                font-family: $FS-Elliot-font;
                color: $grey;
                @include font-size(18);
                font-weight: $regular;

                strong {
                  color: $brandLtBlue;
                  font-weight: $bold;
                }

                span {
                  color: $brandLtBlue;
                  font-weight: $regular;
                }
              }

            }
          }

          .pager {
            padding-left: 0;
            margin: 20px 0;
            list-style: none;
            text-align: center;
            @include clearfix;

            li {
              display: inline;
              > a,
              > span {
                display: inline-block;
                padding: 5px 14px;
                background-color: $pager-bg;
                border: 1px solid $pager-border;
                @include border-radius(15px);
              }

              > a:hover,
              > a:focus {
                text-decoration: none;
                background-color: $pager-hover-bg;
              }
            }

            .next {
              > a,
              > span {
                float: right;
              }
            }

            .previous {
              > a,
              > span {
                float: left;
              }
            }

            .disabled {
              > a,
              > a:hover,
              > a:focus,
              > span {
                color: $pager-disabled-color;
                background-color: $pager-bg;
                cursor: not-allowed;
              }
            }
          }

        }
      }

      .events-section {

        .events-wrapper {
          @include flex-container(row, space-between);
          flex-wrap: wrap;
          width: 93.75vw;
          margin: 0 auto;

          .event-block {
            position: relative;
            padding-bottom: 110px;
            width: 31.62%;
            margin-bottom: 30px;
            border: 1px solid $brandDkBlue;
            @include border-radius(6px);
            overflow: hidden;

            .event-content {

              &.wysiwyg {
                padding: 15px 0;
              }
            }

            img {
              width: 100%;
            }

            h3, p {
              padding: 0 15px;
            }

            h3 {
              @include font-size(28);
              color: $brandDkBlue;
              font-weight: $bold;

              & + p {
                @include font-size(22);
                color: $brandLtBlue;
              }
            }

            .event-footer {
              position: absolute;
              bottom: 0;
              display: block;
              width: 100%;
              height: 100px;
              overflow: hidden;

              h5 {
                padding: 0 15px 0 0;

                &.event-alt-text {
                  position: absolute;
                  left: 15px;
                  bottom: 23px;
                  @include font-size(24);
                  color: $brandSecondary;
                  font-weight: $bold;
                }
              }

              a {
                padding-left: 15px;

                &.event-btn {
                  position: absolute;
                  left: 15px;
                  bottom: 23px;
                }
              }
            }
          }
        }
      }

      .steps-section {
        padding: 50px 4.140vw;
        background-color: $lt-grey;

        .steps-container {
          margin: 0 auto -50px;
          @include flex-container(row, space-between);
          flex-wrap: wrap;

          & + .steps-container {
            margin-top: 50px;
          }


          & > div {
            position: relative;
            color: $brandDkBlue;
            text-align: center;

            &.block {
              border:3px solid $brandLtBlue;
              @include border-radius(20px);
              margin-bottom: 5.859vw;

              & > a {
                display: block;
                padding:1.953vw 2.343vw;
                height:100%;
                color: $brandDkBlue;
                transition: all 0.5s ease-in-out;

                h3 {
                  @include font-size(24);
                  font-weight: $bold;
                  color: $brandLtBlue;
                  transition: all 0.5s ease-in-out;
                }

                p {
                  @include font-size(18);
                  font-weight: $regular;
                }

                &:hover {
                  background-color: $brandLtBlue;
                  color: white;

                  h3 {
                    color: $white;
                  }
                }
              }
            }

            &.size1of3 {
              width: 26.953vw;
            }

            &.size1of2 {
              width: 43.124vw;
            }

          }

          .step1 {
            grid-area: step1;
          }
          .step2 {
            grid-area: step2;
          }
          .step3 {
            grid-area: step3;
          }
          .step4 {
            grid-area: step4;
          }
          .step5 {
            grid-area: step5;
          }
          .step6 {
            grid-area: step6;
          }
        }
      }

      .case-studies {
        position: relative;
        margin: 40px 0;

        .slider {

          .flexslider {
            margin: 0 90px;
          }

          .slide-content {
            @include flex-container(row, space-between);

            .case-study-text {
              width: 60%;

              h2 {
                @include font-size(28);
                font-weight: $bold;
                color: $brandLtBlue;
              }

              h3 {
                @include font-size(28);
                font-weight: $bold;
                color: $brandDkBlue;
              }

              p {

                @include font-size(20);
                font-weight: $regular;
              }
            }

            .case-study-image {
              position: relative;
              width:40%;
              padding:60px 0 40px;

              img {
                //@include centerV;
                display: block;
                width: 100%;
              }
            }

            .btn-wrapper {
              margin-bottom: 0;
            }
          }
        }
      }

      .double-image-section {

        .image-block {

          img {
            display: block;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .twin-image {
        margin: 50px 0 0;

        .twin-image-content {
          @include flex-container();
          padding: 0 36px
;
          .image-block {
            width: 585px;


          }
        }
      }

      .full-width-img {

        img {
          display: block;
          width: 100%;
        }
      }

      .coloured-link-block-section {
        @include flex-container(row, flex-start);
        flex-wrap: wrap;

        .coloured-link-block {
          position: relative;
          height:150px;
          width: 33.333%;

          a {
            @include fill-parent;
            display: block;
            line-height: 1;
            text-decoration: none;
            text-align: center;
            color: $white;
            @include font-size(28);
            font-weight: $regular;
            transition: all, 0.3s ease-in-out;

            &:hover {
              transform: scale(1.1);
            }
          }

          h3 {
            @include centerV;
            width: 100%;
            text-align: center;
          }
        }
      }

      .audience-flex {

        .audience-content {

          .audience-row {
            @include flex-container(row, space-around);
            padding-left: 4.685vw;
            margin: 4.685vw 0;

            &:last-child {
              margin-bottom: 0;
            }

            & > div {
              position: relative;
              height: 23.437vw;
              margin-right: 4.685vw;
              overflow: hidden;
              @include border-radius(6px);

              a {
                @include fill-parent;
                transition: all, 0.3s ease-in-out;

                .block-inner-content {
                  @include centerV;
                  bottom: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  padding: 0;

                  h2 {
                    @include centerV;
                    @include font-size(28);
                    text-align: center;
                    font-weight: $regular;
                    width: 100%;

                    &:before {
                      content: '';
                      position: absolute;
                      left: calc(50% - 70px);
                      top: -140px;
                      display: block;
                      width: 140px;
                      height: 140px;
                      background: transparent url("#{$pathToImages}/icon-sprite-2x.png") no-repeat;
                      background-size: 700px 1120px;
                      background-position: 0 -100%;
                    }
                  }
                }

                &:hover {
                  transform: scale(1.1);
                }
              }
            }

            &.double {

              & > div {
                width: 44.84vw;
              }
            }

            &.treble {

              & > div {
                width: 28.125vw;
              }
            }
          }

          .checklist {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 0!important;
                }
              }
            }
          }

          .data {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px 0!important;
                }
              }
            }
          }

          .forum {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px 0!important;
                }
              }
            }
          }

          .form {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px 0!important;
                }
              }
            }
          }

          .website-useful-info {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -140px!important;
                }
              }
            }
          }

          .training {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -140px!important;
                }
              }
            }
          }

          .funding {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -140px!important;
                }
              }
            }
          }

          .care {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -140px!important;
                }
              }
            }
          }

          .safeguard {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -280px!important;
                }
              }
            }
          }

          .survey {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -280px!important;
                }
              }
            }
          }

          .job {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -280px!important;
                }
              }
            }
          }

          .human-resources {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -280px!important;
                }
              }
            }
          }

          .search {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -420px!important;
                }
              }
            }
          }

          .news {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -420px!important;
                }
              }
            }
          }

          .childcare {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -420px!important;
                }
              }
            }
          }

          .leader {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -420px!important;
                }
              }
            }
          }

          .governor {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -560px!important;
                }
              }
            }
          }

          .teacher {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -560px!important;
                }
              }
            }
          }

          .support {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -560px!important;
                }
              }
            }
          }

          .info {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -560px!important;
                }
              }
            }
          }

          .laptop {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -700px!important;
                }
              }
            }
          }

          .envelope {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -700px!important;
                }
              }
            }
          }

          .blank {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -100% -100%!important;
                }
              }
            }
          }

        }
      }

      .audience-grid {
        height:auto;

        .audience-content {
          width: 93.75vw;
          margin: 0 auto;
          padding: 60px 0;
          display: grid;
          grid-template-columns: 28.125vw 4.685vw 11.718vw 4.685vw 11.718vw 4.685vw 28.125vw;
          grid-template-rows: 23.437vw 4.685vw 23.437vw;
          grid-template-areas:
                  "childcare childcare childcare . leader leader leader"
                  ". . . . . . ."
                  "governor . teachers teachers teachers . support";

          & > div {
            @include border-radius(6px);
            overflow: hidden;

            a {
              position: relative;
              transition: all, 0.3s ease-in-out;

              .block-inner-content {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 0 150px 0;

                h2 {
                  @include font-size(28);
                  text-align: center;
                  font-weight: $regular;
                  width: 100%;
                }
              }

              &:hover {
                transform: scale(1.1);
              }
            }
          }

            .childcare {
              grid-area: childcare;

              a {

                h2 {
                  padding-top: 88px;
                  background: transparent url("#{$pathToImages}/childcare-icon.png") 50% 0 no-repeat;
                  background-size: 100px 65px;
                  overflow: visible;
                }
              }
            }

            .leader {
              grid-area: leader;

              a {

                h2 {
                  padding-top: 74px;
                  background: transparent url("#{$pathToImages}/senior-leader-icon.png") 50% 0 no-repeat;
                  background-size: 100px 51px;
                  overflow: visible;
                }
              }
            }

            .governor {
              grid-area: governor;

              a {

                h2 {
                  padding-top: 110px;
                  background: transparent url("#{$pathToImages}/guvernor-icon.png") 50% 0 no-repeat;
                  background-size: 70px 88px;
                  overflow: visible;
                }
              }
            }

            .teachers {
              grid-area: teachers;

              a {

                h2 {
                  padding-top: 90px;
                  background: transparent url("#{$pathToImages}/teaching-staff-icon.png") 50% 0 no-repeat;
                  background-size: 91px 68px;
                  overflow: visible;
                }
              }
            }

            .support {
              grid-area: support;

              a {

                h2 {
                  padding-top: 71px;
                  background: transparent url("#{$pathToImages}/support-staff.png") 50% 0 no-repeat;
                  background-size: 102px 50px;
                  overflow: visible;
                }
              }
            }
          }
        }

      .feature-slider {

        .slides {

          .slide {

            .slide-content {
              @include flex-container(row, flex-start);

              .slide-block {
                position: relative;
                overflow: hidden;
                width: 50%;
                min-height:27.343vw;

                a {
                  @include fill-parent;
                  display: block;
                  position: relative;
                  color: $white;
                  text-align: center;

                  .block-inner-content {
                    padding: 100px 60px 0;
                    @include fill-parent;
                    font-weight: $regular;

                    h2 {
                      @include font-size(28);
                      margin-bottom: 40px;
                    }

                    p {
                      @include font-size(18);
                    }
                  }
                }

                &.image-block {

                  a {

                    img {
                      display: block;
                      z-index: 2;
                      object-fit: cover;
                      //width: 100%;
                      filter: grayscale(1) contrast(3);
                      opacity: 0.07;
                    }

                    .block-inner-content {

                      h2 {
                        @include centerer;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .two-col {
        background-color: $lt-grey-bg;
        height: auto;
        min-height: 200px;

        .two-col-content {
          padding: 60px 40px;
          @include flex-container(row, space-between);

          .double-col {
            width: 780px;

            h2 {

              &.section-title {
                @include font-size(35);
                color: $brandPrimary;
              }
            }

            ul {

              &.date-list {
                margin-top: 60px;
                border-top: 1px solid $brandLtBlue;

                li {
                  position: relative;
                  height: 60px;
                  border-bottom: 1px solid $brandLtBlue;

                  a {
                    @include flex-container(row, flex-start);
                    height: 60px;
                    width:100%;
                    text-decoration: none;
                    background: transparent url("#{$pathToImages}/Chevron-right-blue.png") 96% 50% no-repeat;
                    background-size: 11px 18px;

                    p {
                      @include font-size(18);
                      color: $brandPrimary;
                      line-height: 60px;

                      &:nth-child(1) {
                        width: 110px;
                      }
                    }
                  }
                }
              }

              & + a {

                &.primary-btn {
                  margin-top: 30px;
                }
              }
            }
          }

          .single-col {
            max-width: 360px;

            .quote-wrapper {

              .quote {
                @include font-size(35);
                line-height: 44px;
                color: $brandPrimary;
              }

              .attributed {
                @include font-size(18);
                color: $brandPrimary;
              }
            }
          }
        }
      }

      .signup-frm {
        height:auto;

        .signup-frm-content {
          padding: 60px 40px;
          position: relative;
          color: $white;
          align-items: center; 

          a {
            color: $white;
          }

          .signup-form {
            .form-item {

              input,
              textarea {
                 display: inline-block;
                 line-height: 50px;
                 background-color: transparent;
                 border:2px solid $white;
                 @include border-radius(6px);
                 margin: 0 0 1em 0;
                 padding: 0 20px;
                 color: $white;
                 @include font-size(20);

                 &::placeholder {
                   color: $white;
                   @include font-size(20);
                 }
               }

              textarea {
                height:47px;
                line-height: 22px;
                padding: 0 20px;
                font-family: $FS-Elliot-font;
                @include font-size(20);

                &::placeholder {
                  font-family: $FS-Elliot-font;
                  line-height: 42px;
                }
              }

              select {
                display: inline-block;
                width: 360px;
                height:50px;
                line-height: 50px;
                background-color: transparent;
                border:2px solid $white;
                @include border-radius(6px);
                padding: 0 20px;
                color: $white;
                @include font-size(20);
              }
            }

            .form-actions {

              input[type=submit] {
                display: inline-block;
                width: 360px;
                height:50px;
                line-height: 50px;
                background-color: $white;
                border:2px solid $white;
                @include border-radius(6px);
                padding: 0 20px;
                color: $brandLtBlue;
                cursor: pointer;
                @include font-size(20);
                -webkit-appearance: button!important;

                &:hover {
                  transition: all, 0.3s ease-in-out;
                  background-color: $brandLtBlue;
                  color: $white;
                }
              }
            }
          }

          .form-intro {
            position: relative;
            @include font-size(20);
          }

          .name {
            grid-area: name;
          }

          .email {
            grid-area: email;
          }

          .select {
            grid-area: select;
          }

          .button {
            grid-area: button;
          }

          .privacy {
            position: absolute;
            left:880px;
            bottom:60px;
            width: 360px;
            @include font-size(14);
          }
        }
      }

      .social-share {
        padding: 0 36px;

        h3 {
          @include font-size(16);
          font-weight: $regular;
          margin: 6px 0;
        }

        .social-links {

          a {
            display: inline-block;
            height: 30px;
            width: 30px;
            color: $white;
            @include font-size(22);
            text-align: center;
            line-height: 31px;

            &:not(:last-child) {
              margin-right: 4px;
            }

            &.facebook {
              background-color: $facebook;
            }

            &.twitter {
              background-color: $twitter;
            }

            &.pinterest {
              background-color: $pinterest;
            }

            &.linkedin {
              background-color: $linkedin;
            }
          }
        }

        & + .content {
          margin-top: -32px;
        }
      }

      .link-box-row {
        margin-top: 60px;
        margin-bottom: 60px;

        & + .link-box-row {
          margin-top: 0;
        }

        .link-box-row-content {
          @include flex-container(row, space-between);
          width: 93.75vw;
          margin: 0 auto;

          & > div {
            width: 31.62%;
            min-height: 300px;
            position: relative;
            @include border-radius(6px);
            overflow: hidden;


            a {
              @include fill-parent;
              color: $white;
              transition: all, 0.3s ease-in-out;

              .block-inner-content {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 0 85px 0;

                h2 {
                  @include font-size(24);
                  text-align: center;
                  font-weight: $regular;
                  width: 100%;

                  &:before {
                    content: '';
                    position: absolute;
                    left: calc(50% - 70px);
                    top: -140px;
                    display: block;
                    width: 140px;
                    height: 140px;
                    background: transparent url("#{$pathToImages}/icon-sprite-2x.png") no-repeat;
                    background-size: 700px 1120px;
                    background-position: 0 -100%;
                  }
                }
              }

              &:hover {
                transform: scale(1.1);
              }
            }
          }

          .checklist {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 0!important;
                }
              }
            }
          }

          .data {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px 0!important;
                }
              }
            }
          }

          .forum {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px 0!important;
                }
              }
            }
          }

          .form {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px 0!important;
                }
              }
            }
          }

          .website-useful-info {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -140px!important;
                }
              }
            }
          }

          .training {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -140px!important;
                }
              }
            }
          }

          .funding {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -140px!important;
                }
              }
            }
          }

          .care {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -140px!important;
                }
              }
            }
          }

          .safeguard {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -280px!important;
                }
              }
            }
          }

          .survey {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -280px!important;
                }
              }
            }
          }

          .job {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -280px!important;
                }
              }
            }
          }

          .human-resources {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -280px!important;
                }
              }
            }
          }

          .search {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -420px!important;
                }
              }
            }
          }

          .news {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -420px!important;
                }
              }
            }
          }

          .childcare {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -420px!important;
                }
              }
            }
          }

          .leader {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -420px!important;
                }
              }
            }
          }

          .governor {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -560px!important;
                }
              }
            }
          }

          .teachers {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -560px!important;
                }
              }
            }
          }

          .support {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -560px!important;
                }
              }
            }
          }

          .info{

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -560px!important;
                }
              }
            }
          }

          .laptop {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -700px!important;
                }
              }
            }
          }

          .envelope {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -140px -700px!important;
                }
              }
            }
          }

          .about-us{

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -280px -700px!important;
                }
              }
            }
          }

          .wf-trade-services {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: 0 -700px!important;
                }
              }
            }
          }

          .get-in-touch {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -420px -700px!important;
                }
              }
            }
          }

          .blank {

            a {

              h2 {

                &:before {
                  top:-140px;
                  background-position: -100% -100%!important;
                }
              }
            }
          }
        }
      }

      .page-last-update {

        div {
          text-align: right;

          span {
            padding: 7px 3px;
          }
        }
      }
    }

    footer {
      min-height: 265px;
      background-color: $accentGrey;
      color: $white;
      overflow: hidden;


      .footer-content {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 40px;
        display: grid;
        grid-template-rows: 145px 120px;
        grid-template-columns: 23% 47% 15% 15%;
        grid-template-areas: "sign-up-social sign-up-social . ."
        "footer-nav copy partner-logo wf-logo";
      }

      & > div {
        color: $white;
      }

      .sign-up-social {
        grid-area: sign-up-social;
        padding-top: 80px;
        @include flex-container(row, flex-start);

        .sign-up {
          display: inline-block;
          padding-left: 13px;

          a {
            @include font-size(20);
            font-weight: $regular;
            line-height: 50px;
            color: $white;

          }
        }

        .sign-up-form {
          @include flex-container(row, flex-start);

          input {
            display: inline-block;
            height: 50px;
            line-height: 50px;
            padding: 0 20px;
            border: none;
            color: $white;
            @include font-size(20);
            background-color: $dk-grey;

            &::placeholder {
              color: $white;
              @include font-size(20);
            }

            &[type=submit] {
              width: 50px;text-indent: -10000px;
              background: $dk-grey url("#{$pathToImages}/Chevron-right.png") 50% 50% no-repeat;
              background-size: 7px 12px;
              cursor: pointer;
              
            }
          }
        }

        .social {
          margin-left: 40px;

          a {
            color: $white;
            @include font-size(36);
          }
        }
      }

      .footer-nav {
        grid-area: footer-nav;
        padding-top: 50px;

        nav {

          ul {
            height: 30px;
            @include flex-container(row, flex-start);

            li {

              a {
                color: $white;
                @include font-size(18);
                font-weight: $regular;
                text-decoration: none;
                padding-left: 12px;


              }

              &:not(:last-child) {

                a {
                  padding-right: 12px;
                  border-right: 1px solid $white;
                }
              }
            }
          }
        }
      }

      .copy {
        grid-area: copy;
        padding-top: 50px;

        p {
          @include font-size(18);
        }
      }

      .partner-logo {
        grid-area: partner-logo;
        @include flex-container(row, flex-start);
        padding-top: 31px;


        .logo {
          display: block;
          width: 173px;
          height: 38px;
          background: transparent url(../assets/images/traded-services-logo.png) 0 0 no-repeat;
          background-size: 173px 38px;
        }
      }

      .wf-logo {
        grid-area: wf-logo;
        @include flex-container(row, flex-end);
        padding-top: 10px;

        .logo {
          display: block;
          width: 103px;
          height: 57px;
          background: transparent url(../assets/images/Waltham-Forest-logo.png) 0 0 no-repeat;
          background-size: 103px 57px;
        }
      }
    }
  }
}