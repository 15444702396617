/****************************************
*
* Base stylesheet
*
*****************************************/

html {
  font-size: $baseFontSize;
}

html,
body {
  width:100%;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  border: 0;
}

body {
  font-family: $FS-Elliot-font;
  font-weight: $regular;
  color: $txtColour;
  @include font-size(14);

  & > section {
    overflow: hidden;
  }
}

a {
  color: $brandLtBlue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.btn {

    display: inline-block;
    padding:  0 50px;
    height: 45px;
    min-width: 220px;
    @include font-size(18);
    font-weight: $regular;
    @include border-radius(6px);
    border: 2px solid $brandLtBlue;
    color: $brandLtBlue;
    line-height: 45px;
    text-decoration: none;
    text-align: center;
    transition: all 0.5s ease-in-out;

    &.primary-btn {
      border: 2px solid $brandLtBlue;
      color: $brandLtBlue;
      position: relative;

      &:hover {
        background-color: $brandLtBlue;
        color: $white;
      }

      &.next {

        &:after {
          font-family: FontAwesome;
          content: '\f054';
          position: absolute;
          right:10px;
          top: 0;
        }
      }

      &.prev {

        &:before {
          font-family: FontAwesome;
          content: '\f053';
          position: absolute;
          left:10px;
          top: 0;
        }
      }
    }

    &.secondary-btn {
      border: 2px solid $brandDkBlue;
      color: $brandDkBlue;

      &.next {

        &:after {
          font-family: FontAwesome;
          content: '\f054';
          position: absolute;
          right:10px;
          top: 0;
        }
      }

      &.prev {

        &:before {
          font-family: FontAwesome;
          content: '\f053';
          position: absolute;
          left:40px;
          top: 0;
        }
      }

      &:hover {
        background-color: $brandDkBlue;
        color: $white;
      }
    }
  }
}

.btn-wrapper {
  position: relative;
  padding: 36px 0;
  margin-bottom: 30px;

  &.centred {

    a {
      @include centerer;
    }
  }

  &.row {
    @include flex-container(row, flex-start);
    padding: 0 36px;

    & > a {
      margin-right: 25px;
    }
  }
}

/*
* Clearfix: contain floats
*
* For modern browsers
* 1. The space content is one way to avoid an Opera bug when the
* contenteditable attribute is included anywhere else in the document.
* Otherwise it causes space to appear at the top and bottom of elements
* that receive the clearfix class.
* 2. The use of table rather than block is only necessary if using
* :before to contain the top-margins of child elements.
*/

.clearfix:before,
.clearfix:after {
  content: " ";
  display: table;
}

.clearfix:after {
  clear: both;
}

/*
* For IE 6/7 only
* Include this rule to trigger hasLayout and contain floats.
*/

.clearfix {
  *zoom: 1;
}